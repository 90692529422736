header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

.me {
    background: linear-gradient(var(--primary-color), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.scroll__down {
    position: absolute;
    right: 2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/**
  * Media Queries (Medium Devices)
  */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
        margin-bottom: 24rem;
    }
}
/**
  * Media Queries (Small Devices)
  */
@media screen and (max-width: 600px) {
    header {
        padding-top: 1.5rem;
        margin-bottom: 16rem;
    }
    .scroll__down {
        display: none;
    }
}
